<!--
 * @Author: sqk
 * @Date: 2020-08-31 16:53:13
 * @LastEditTime: 2021-01-30 10:44:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\LimitShop.vue
-->



<template>
  <div class="shop-history swiper-box">
    <div class="banner-box">
      <!-- <img :src="data.bannerUrl ? data.bannerUrl : ''" alt /> -->
      <img src="@/assets/product/llzj2.png" alt />
    </div>
    <div class="swiper-container" ref="mySwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item,index) in data" :key="index">
          <!-- <card-item :data="item" type="type2"></card-item> -->
          <!-- <div
            class="card-ani-box animated fadeInRight"
            :style="{ animationDelay: (index + 1) * .1 + 's' }"
          > -->
            <card-item-two
            :key="index"
            :data="item"
            :isRebate="item.isRebate"
            :orgName="item.orgName"
            :productName="item.productName"
            :importInfo="item.productSellingPoint"
            :price="item.price"
            :retailPrice="item.retailPrice"
            :isFenXiao="item.isFenXiao"
            :inventory="item.inventory"
            :sharedInv="item.sharedInv"
            :invQty="item.invQty"
            :gicInvQty="item.gicInvQty"
            :purchaseType="item.purchaseType1"
            :active="item.active"
            :picture="item.img"
            :errorImg="item.defaultImg"
          ></card-item-two>
          <!-- </div> -->
          
        </div>
      </div>
      <!-- 如果需要分页器:style="'border-top:1px solid' + borderColorFun(index)" -->
      <div class="swiper-pagination" ref="pagination"></div>
      <!--箭头。如果放置在swiper-container外面，需要自定义样式。-->
      <!-- <div class="swiper-button-prev animated fadeInRight"></div>
      <div class="swiper-button-next animated fadeInLeft"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "shopHistory",
  data() {
    return {};
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    data: function() {
      // this.initEcharts();
    }
  },
  
  computed: {
  },
  //定义swiper对象
  mounted() {
    let this_ = this;
    this.$nextTick(() => {
      var mySwiper = new this.$Swiper(this.$refs.mySwiper, {
        on: {
          slideChange: function() {
          },
          click: () => {
            // alert("你点了Swiper");
          }
        },
        autoplay: {
          //自动轮播
          delay: 5000
          //   disableOnInteraction: false
          // autoplayDisableOnInteraction : false,
          // stopOnLastSlide:true
        },
        // loop: true, // 循环模式选项
        // direction: "vertical",
        onlyExternal: true,
        // noSwiping : true,
        // simulateTouch: false,//鼠标模拟手机触摸。默认为true，Swiper接受鼠标点击、拖动。
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 12,
        // preventLinksPropagation:false,//防止冒泡。
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        //         // 如果需要分页器
        pagination: {
          el: this_.$refs.pagination,
          //   paginationClickable: true,
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      });
      mySwiper.el.onmouseover = function() {
        //鼠标放上暂停轮播
        mySwiper.autoplay.stop();
      };
      mySwiper.el.onmouseleave = function() {
        mySwiper.autoplay.start();
      };
    });
  },
  methods: {}
};
</script>
<style lang="less" >
.shop-history.swiper-box {
  width: 1188px;
  height: 548px;
  //   margin: 0 auto;
  //   display: flex;
  /* justify-content: center; */
  //   align-items: center;
  .banner-box {
    width: 100%;
    height: 130px;
  }
  .swiper-container {
    // margin-left: 12px;
    width: 1188px;
    height: 393px;
    float: left;
    padding: 12px 12px 28px;
    box-sizing: content-box;
    margin-left: -12px;
    .swiper-slide {
      width: 288px;
      height: 380px;
      // background: #ffffff;
      .card-ani-box{
        margin-top: 0;
      }
    }
  }

  
}
</style>

