import { mapState } from "vuex";
// 导入api接口
import {
  getPrices, //获取产品价格接口
  getStocks, //获取产品库存接口
  getChannelPrice, //获取DMS产品价格接口
  getChannelStock, //获取DMS产品库存接口
  queryCustBrowseRecord, //商家浏览记录列表接口
} from "@/utils/api.js";
import { ctsApi } from "@/views/channel/api";
import { getChannelStockLIST, productSearch } from "../product/api.js";
import util from "../product/components/util";
import shopHistory from "./components/ShopHistory";
import sortBar from "./components/SortBar";
// import { productSearch } from "./api.js"; // 导入api接口
export default {
  name: "Home",
  components: {
    shopHistory,
    sortBar,
  },
  data() {
    return {
      windowList: [],
      sortList: [],
      dataList: [],
      total: 0, //数据总量

      historyList: [], //浏览记录list
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页",
        },
        {
          path: "/product",
          name: "product",
          title: "商品橱窗",
        },
      ],
      param: {
        //获取橱窗列表数据所需参数
        keyword: "", //搜索关键词 	示例:电视机
        catalogId: "", //产品分类id
        productId: "", //产品id
        filter: "", //规格属性拼接字符串
        orgId: "", //供应商id拼接字符串，以逗号分隔
        sortField: "scoreOverall", //排序字段，sale销量，onlineDate上架时间
        sortType: "desc", //排序类型，asc正序 或 desc倒序
        // type: "2", //类型，1是分销（分销系统）渠道采购，2是直营代理（下单系统）海信采购
        matklCodes: "", //物料组编码字符串，多个，逗号分隔
        havePrice: "", //是否带价格，1是0否
        purchaseType: "", //采购类型，1应急采购2常规采购
        pageNum: 1, //页码
        pageSize: 20, //每页条数
      },
      pageLoadFlag: false, //列表数据load标志
      listType: "", //海信采购2渠道采购1
      isChange: false, //筛选条件切换 路由未变
    };
  },
  watch: {
    $route: function() {
      this.param.catalogId = this.$route.query.catalogId
        ? this.$route.query.catalogId
        : "";
      this.param.productId = this.$route.query.productId
        ? this.$route.query.productId
        : "";
      this.param.keyword = this.$route.query.keyword
        ? this.$route.query.keyword
        : "";

      //首页限时活动跳转
      this.param.popularType = this.$route.query.popularType
        ? this.$route.query.popularType
        : "";
      this.param.matklCodes = this.$route.query.matklCodes
        ? this.$route.query.matklCodes
        : "";
      this.param.matklName = this.$route.query.matklName
        ? this.$route.query.matklName
        : "";
      // this.param.type = this.$route.query.type ? this.$route.query.type : "2";
      if (this.param.catalogId || this.param.productId) {
        // this.param.pageNum = 1;
      } else {
        //去除最后的产品项
        this.breadcrumbData = this.breadcrumbData.filter((e, idx) => {
          return idx < 2;
        });
      }
      this.isChange = true;
      this.param.pageNum = 1;
      // this.getProductSearch();
      //请求筛选公共接口 获取筛选数据
      this.$store.dispatch("filtrate/systemConfigFormItem").then(() => {
        this.isChange = false;
      });
      // //改变面包屑
      // let breadcrumbLast = {
      //   title: this.$route.query.tagNameL1
      //     ? this.$route.query.tagNameL2
      //       ? this.$route.query.tagNameL1 + "-" + this.$route.query.tagNameL2
      //       : this.$route.query.tagNameL1
      //     : "",
      // };
      let breadcrumbLast = {
        title: this.$route.query.tagNameL1 ? this.$route.query.tagNameL1 : "",
      };
      if (breadcrumbLast.title) {
        this.$set(this.breadcrumbData, 2, breadcrumbLast);
      }
    },
    filterLoadFlag() {
      // if(this.filterLoadFlag){
      // }
    },
  },
  computed: {
    ...mapState({
      productImgBaseUrl: (state) =>
        state.user.productImgBaseUrl
          ? state.user.productImgBaseUrl
          : localStorage.getItem("productImgBaseUrl"),
      //处理后的筛选数据list
      filList: (state) => state.filtrate.filList,
      //筛选组件加载loadding
      filterLoadFlag: (state) => state.filtrate.isLoading,
      //用于查询列表的筛选配置字段
      filterConfigStr: (state) => state.filtrate.filterConfigStr,
      userInfo() {
        return this.$store.state.user.userInfo
          ? this.$store.state.user.userInfo
          : localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo"))
          : "";
      }
    }),
  },
  created() {
    // if (this.$route.query.tagName) {
    //   this.param.keyword = this.$route.query.tagName;
    // }
    if (this.$route.query.catalogId) {
      this.param.catalogId = this.$route.query.catalogId;
    }
    if (this.$route.query.productId) {
      this.param.productId = this.$route.query.productId;
    }
    this.param.keyword = this.$route.query.keyword
      ? this.$route.query.keyword
      : "";

    //首页限时活动跳转
    this.param.popularType = this.$route.query.popularType
      ? this.$route.query.popularType
      : "";
    this.param.matklCodes = this.$route.query.matklCodes
      ? this.$route.query.matklCodes
      : "";
    this.param.matklName = this.$route.query.matklName
      ? this.$route.query.matklName
      : "";

    let breadcrumbLast = {
      title: this.$route.query.tagNameL1 ? this.$route.query.tagNameL1 : "",
    };
    if (breadcrumbLast.title) {
      this.$set(this.breadcrumbData, 2, breadcrumbLast);
    }
    breadcrumbLast = {
      title:
        this.param.matklName +
        " | " +
        (this.param.popularType == "popularProduct"
          ? "爆款"
          : this.param.popularType == "newProduct"
          ? "新品"
          : "热销"),
    };
    // let name = this.param.popularType == 'popularProduct' ? '爆款' : this.param.popularType == 'newProduct' ? '新品' : '热销'
    if (breadcrumbLast.title && this.param.matklName) {
      this.$set(this.breadcrumbData, 2, breadcrumbLast);
    }
  },
  mounted() {
    //筛选后续操作的入参 加后台已定义的 formCode 便于筛选后查询
    this.param = {
      ...this.param,
      formCode: this.filterConfigStr
        ? this.filterConfigStr +
          (this.$route.query.catalogId ? this.$route.query.catalogId : "")
        : "",
    };
    //请求筛选公共接口 获取筛选数据
    // this.$store.dispatch("filtrate/systemConfigFormItem");
    //排序数据（相当于写死排序条件）
    this.sortList = util.sortList;

    //橱窗列表数据查询接口
    // this.getProductSearch();
    //商家浏览记录列表接口
    this.queryCustBrowseRecord();
  },
  methods: {
    getRebateProduct(list){
      try {
        ctsApi({
          serviceCode: "productDetail",
          bigContractNo: "",
          companyCode: "",
          contractNo: "",
          // distributorsCisCode: response.data.account.account,
          distributorsCisCode: "",
          dlMdmCode: this.$store.state.user.userInfo.customer.customerCode,
          model: "",
          tableRow: "",
          isActivityArea: false
        }).then(rebateRes=>{
          list.forEach(item => {
            if(rebateRes && rebateRes.data && rebateRes.data.data && rebateRes.data.data.length > 0){
              const findIndex = rebateRes.data.data.findIndex(el => el.companyCode == item.orgCode && el.model == (item.modelSap || item.productName) && (el.pricingGroup && item.pricingGroupCode == el.pricingGroup))
              if(findIndex > -1){
                item.isRebate = true;
              }
            }else{
              item.isRebate = false;
            }
          })
        })
      } catch (error) {
      }
    },
    // 跳转页面
    handlePageChange(page) {
      this.param.pageNum = page;
      this.getProductSearch();
    },

    onFilterChange(checkedObj) {
      //重置筛选条件 将新条件融合进去
      // this.setNullParams();

      Object.assign(this.param, checkedObj[0]);

      // 查询产品列表
      this.param.pageNum = 1;
      this.getProductSearch();
    },
    //获取价格
    getPrices(idx, param, code) {
      getPrices(param)
        .then((pricesRes) => {
          // 源数据赋值库存
          // if (pricesRes.statusText == "OK") {
          // 划线价格（提货价）
          this.$set(
            code == "history" ? this.historyList[idx] : this.dataList[idx],
            "standardPrice",
            pricesRes.data[0].standardPrice
              ? pricesRes.data[0].standardPrice.toFixed(2)
              : null
          );
          // 标准价格（正常价）
          this.$set(
            code == "history" ? this.historyList[idx] : this.dataList[idx],
            "price",
            pricesRes.data[0].price ? pricesRes.data[0].price.toFixed(2) : null
          );
          this.$forceUpdate();
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getChannelPrice(idx, param, code) {
      getChannelPrice(param)
        .then((pricesRes) => {
          this.$set(
            code == "history" ? this.historyList[idx] : this.dataList[idx],
            "price",
            pricesRes.data.list[0].standardPrice
              ? pricesRes.data.list[0].standardPrice.toFixed(2)
              : null
          );
          this.$forceUpdate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //获取库存
    getStocks(idx, param, code) {
      // 根据产品列表获取库存
      getStocks(param)
        .then((stocksRes) => {
          // 源数据赋值库存
          // if (stocksRes.statusText == "OK") {
          this.$set(
            code == "history" ? this.historyList[idx] : this.dataList[idx],
            "inventory",
            stocksRes.data[0].inventory
          );
          this.$set(
            code == "history" ? this.historyList[idx] : this.dataList[idx],
            "sharedInv",
            stocksRes.data[0].sharedInv
          );
          this.$forceUpdate();
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //获取库存
    getChannelStock(idx, param, code) {
      // 根据产品列表获取库存
      getChannelStock(param)
        .then((stocksRes) => {
          let res = JSON.parse(stocksRes.data);
          // if(stocksRes.status == 200 && res.code == 0){
          // 库存
          this.$set(
            code == "history" ? this.historyList[idx] : this.dataList[idx],
            "invQty",
            res.data[0].invQty
          );

          this.$set(
            code == "history" ? this.historyList[idx] : this.dataList[idx],
            "gicInvQty",
            res.data[0].gicInvQty
          );
          // }

          this.$forceUpdate();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getProductSearch() {
      this.pageLoadFlag = true;
      // let params = this.param;

      // productSearch(this.param)
      //   .then((res) => {
      //     if(res){
      //       this.total = res.data.totalRows;
      //       this.pageLoadFlag = false;
      //       this.dataList = res.data.list;

      //       if (res && res.data.list && res.data.list.length > 0) {
      //         for (const idx in res.data.list) {
      //           let param = {
      //             code: res.data.list[idx].productCode,
      //             orgId: res.data.list[idx].orgId,
      //             orgCode: res.data.list[idx].orgCode,
      //           };
      //           this.getPrices(idx, param);
      //           // this.getStocks(idx, param);
      //         }
      //       }
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     this.pageLoadFlag = false;
      //   });

      try {
        const res = await productSearch(this.param);
        if (res) {
          let hxArr = [];
          let qdArr = [];
          this.total = res.data.totalRows;
          this.pageLoadFlag = false;
          if(res.data.list.length > 0){
            res.data.list.forEach(item => {
              item.isRebate = false;
            })
          }
          this.dataList = res.data.list;
          let orgIds = res.data.orgIds
          // this.dataList = util.list;
          if (res && this.dataList && this.dataList.length > 0) {
            for (const idx in this.dataList) {
              //添加唯一id orgId+productId
              this.$set(
                this.dataList[idx],
                "wyId",
                this.dataList[idx].orgId.toString() +
                  this.dataList[idx].id.toString()
              );

              //海信采购和渠道采购 分两组
              if (this.dataList[idx].isFenXiao == "N") {
                //海信采购
                hxArr.push(this.dataList[idx]);
              } else {
                //渠道采购
                qdArr.push(this.dataList[idx]);
              }

              //建议零售价
              this.$set(
                this.dataList[idx],
                "retailPrice",
                this.dataList[idx].retailPriceL
                  ? Number(this.dataList[idx].retailPriceL)
                      .toFixed(2)
                      .toString()
                  : null
              );
            }
            if (hxArr && hxArr.length > 0) {
              //唯一Id数组
              let wyIdArr = [];

              let hxParams = {};
              let codeArr = [],
                orgIdArr = [],
                orgCodeArr = [];
              for (const hxidx in hxArr) {
                codeArr.push(hxArr[hxidx].productCode);
                orgIdArr.push(hxArr[hxidx].orgId);
                orgCodeArr.push(hxArr[hxidx].orgCode);
                hxParams = {
                  code: codeArr.join(","),
                  orgId: orgIdArr.join(","),
                  orgCode: orgCodeArr.join(","),
                };
                wyIdArr.push(hxArr[hxidx].wyId);
              }

              let  paramDmsStock = {
                productCodes: codeArr,
                supplierCode: '',
              };
              this.getChannelStockNewList(paramDmsStock, codeArr, this.dataList);
              this.getPricesNew(hxParams, wyIdArr, this.dataList);
              this.getStocksNew(hxParams, wyIdArr, this.dataList);
            }

            if (qdArr && qdArr.length > 0) {
              //唯一Id数组
              let wyIdArr = [];
              let paramDmsPrice = {
                orgId: [],
                productId: [],
              };
              let paramDmsStock = {
                productCodes: [],
                supplierCode: "",
              };
              for (const qdidx in qdArr) {
                paramDmsPrice.orgId.push(qdArr[qdidx].orgId);
                paramDmsPrice.productId.push(qdArr[qdidx].productCode);
                paramDmsStock.productCodes.push(qdArr[qdidx].productCode);
                wyIdArr.push(qdArr[qdidx].wyId);
              }
              paramDmsPrice = {
                orgId: paramDmsPrice.orgId.join(","),
                productId: paramDmsPrice.productId.join(","),
              };
              paramDmsStock = {
                productCodes: paramDmsStock.productCodes,
                supplierCode: qdArr[0].agentCisCode,
              };
              this.getChannelPriceNew(paramDmsPrice, wyIdArr, this.dataList);

              // 分组传参 把商品列表数据根据agentCisCode 进行分组，传参数
              var map = {},
                dest = [];
              for(var i = 0; i < this.dataList.length; i++){
                var ai = this.dataList[i];
                if(!map[ai.agentCisCode]){
                  dest.push({
                    agentCisCode: ai.agentCisCode,
                    // name: ai.agentCisCode,
                    data: [ai]
                  });
                  map[ai.agentCisCode] = ai;
                }else{
                  for(var j = 0; j < dest.length; j++){
                    var dj = dest[j];
                    if(dj.agentCisCode == ai.agentCisCode){
                      dj.data.push(ai);
                      break;
                    }
                  }
                }
              }
              dest.forEach(item=>{
                let dataS = {
                  supplierCode:item.agentCisCode,
                  productCodes:item.data.map(it=>it.id)
                }
                let wyIdArr1 = item.data.map(it=>it.wyId)
                this.getChannelStockListNew(dataS, wyIdArr1, item.data);
              })
            }
          }

          // if (this.dataList[idx].isFenXiao == "N") {
          //   let param = {
          //     code: this.dataList[idx].productCode,
          //     orgId: this.dataList[idx].orgId,
          //     orgCode: this.dataList[idx].orgCode,
          //   };
          //   this.getPrices(idx, param);
          //   this.getStocks(idx, param);
          // } else if (this.dataList[idx].isFenXiao == "Y") {
          //   let paramDmsPrice = {
          //     orgId: this.dataList[idx].orgId,
          //     productId: this.dataList[idx].productCode,
          //   };
          //   let paramDmsStock = {
          //     productCodes: [this.dataList[idx].productCode],
          //     supplierCode: this.dataList[idx].agentCisCode,
          //   };
          //   this.getChannelPrice(idx, paramDmsPrice);
          //   this.getChannelStock(idx, paramDmsStock);
          // }
        }
      } catch (error) {
        console.log(error);
      }
    },

    //海信采购获取价格 param:价格接口入参，逗号分隔；wyIdArr：唯一Id数组->orgId + productId 用于数据价格比对匹配；dataListArr：数据来源-》商品列表|浏览足迹
    getPricesNew(param, wyIdArr, dataList) {
      getPrices(param)
        .then((pricesRes) => {
          //商品列表
          for (let item of dataList) {
            wyIdArr.forEach((e, idx) => {
              if (item.wyId == e) {
                // 划线价格（提货价）
                this.$set(
                  item,
                  "standardPrice",
                  pricesRes.data[idx].standardPrice
                    ? pricesRes.data[idx].standardPrice.toFixed(2)
                    : null
                );
                // 标准价格（正常价）
                this.$set(
                  item,
                  "price",
                  pricesRes.data[idx].price
                    ? pricesRes.data[idx].price.toFixed(2)
                    : null
                );
                // 定价组code
                this.$set(
                  item,
                  "pricingGroupCode",
                  pricesRes.data[idx].pricingGroupCode
                );
                this.$forceUpdate();
              }
            });
          }
          this.getRebateProduct(dataList)
          // // 划线价格（提货价）
          // this.$set(
          //   code == "history" ? this.historyList[idx] : this.dataList[idx],
          //   "standardPrice",
          //   pricesRes.data[0].standardPrice
          //     ? pricesRes.data[0].standardPrice.toFixed(2)
          //     : null
          // );
          // // 标准价格（正常价）
          // this.$set(
          //   code == "history" ? this.historyList[idx] : this.dataList[idx],
          //   "price",
          //   pricesRes.data[0].price ? pricesRes.data[0].price.toFixed(2) : null
          // );
          // this.$forceUpdate();
        })
        .catch((error) => {});
    },
    //海信采购获取库存
    getStocksNew(param, wyIdArr, dataList) {
      // 根据产品列表获取库存
      getStocks(param)
        .then((stocksRes) => {
          for (let item of dataList) {
            wyIdArr.forEach((e, idx) => {
              if (item.wyId == e) {
                // 划线价格（提货价）
                this.$set(item, "inventory", stocksRes.data[idx].inventory);
                // 标准价格（正常价）
                this.$set(item, "sharedInv", stocksRes.data[idx].sharedInv);
                this.$forceUpdate();
              }
            });
          }

          this.$forceUpdate();
        })
        .catch((error) => {});
    },
    //渠道采购获取价格
    getChannelPriceNew(param, wyIdArr, dataList) {
      getChannelPrice(param)
        .then((pricesRes) => {
          for (let item of dataList) {
            wyIdArr.forEach((e, idx) => {
              if (item.wyId == e) {
                // 标准价格（正常价）
                this.$set(
                  item,
                  "price",
                  pricesRes.data.list[idx].standardPrice
                    ? pricesRes.data.list[idx].standardPrice.toFixed(2)
                    : null
                );
                this.$forceUpdate();
              }
            });
          }
          this.getRebateProduct(dataList)
          this.$forceUpdate();
        })
        .catch((error) => {});
    },
    getChannelStockNewList(param, wyIdArr, dataList) {
      // 根据产品列表获取库存
      getChannelStockLIST(param)
        .then((stocksRes) => {
          let res = JSON.parse(stocksRes.data);
          for (let item of dataList) {
            res.data.forEach(resItem=>{
              if(resItem.productCode == item.productCode) {
                this.$set(item, "invQty", resItem.invQty);
                this.$set(item, "gicInvQty", resItem.gicInvQty);
                this.$forceUpdate()
              }
            })
          }
          this.$forceUpdate();
        })
        .catch((error) => {});
    },
    // 浏览记录调用库存
    getChannelStockNew(param, wyIdArr, dataList) {
      // 根据产品列表获取库存
      getChannelStock(param)
        .then((stocksRes) => {
          let res = JSON.parse(stocksRes.data);
          for (let item of dataList) {
            wyIdArr.forEach((e, idx) => {
              if (item.wyId == e) {
                this.$set(item, "invQty", res.data[idx].invQty);
                this.$set(item, "gicInvQty", res.data[idx].gicInvQty);
                this.$forceUpdate();
              }
            });
          }

          this.$forceUpdate();
        })
        .catch((error) => {});
    },
    // 修改后的商品列表
    getChannelStockListNew(param, wyIdArr, dataList) {
      // 根据产品列表获取库存
      getChannelStock(param)
        .then((stocksRes) => {
          let res = JSON.parse(stocksRes.data);
          for (let item of dataList) {
            // 循环接口中的数据
            res.data.forEach((itemA,indexA) =>{
              wyIdArr.forEach((e, idx) => {
                // 判断列表的商品编码和接口里的编码是否一致，一直的话获取接口的对应index的值
                if (item.wyId == e && itemA.productCode == item.productCode) {
                  this.$set(item, "invQty", res.data[indexA].invQty);
                  this.$set(item, "gicInvQty", res.data[indexA].gicInvQty);
                  this.$forceUpdate();
                }
              });
            })

          }
          this.$forceUpdate();
        })
        .catch((error) => {});
    },
    //浏览足迹
    async queryCustBrowseRecord() {
      this.pageLoadFlag = true;
      try {
        const res = await queryCustBrowseRecord({ num: "12" });
        if (res) {
          if(res.data.list.length > 0){
            res.data.list.forEach(item => {
              item.isRebate = false;
            })
          }
          this.historyList = res.data.data;
          if (res && res.data.data && res.data.data.length > 0) {
            let hxArr = [];
            let qdArr = [];
            for (const idx in this.historyList) {
              //添加唯一id orgId+productId
              this.$set(
                this.historyList[idx],
                "wyId",
                this.historyList[idx].orgId.toString() +
                  this.historyList[idx].productId.toString()
              );

              //海信采购和渠道采购 分两组
              if (this.historyList[idx].isFenXiao == "N") {
                //海信采购
                hxArr.push(this.historyList[idx]);
              } else {
                //渠道采购
                qdArr.push(this.historyList[idx]);
              }

              //建议零售价
              this.$set(
                this.historyList[idx],
                "retailPrice",
                this.historyList[idx].retailPriceL
                  ? Number(this.historyList[idx].retailPriceL)
                      .toFixed(2)
                      .toString()
                  : null
              );
            }

            if (hxArr && hxArr.length > 0) {
              //唯一Id数组
              let wyIdArr = [];

              let hxParams = {};
              let codeArr = [],
                orgIdArr = [],
                orgCodeArr = [];
              for (const hxidx in hxArr) {
                codeArr.push(hxArr[hxidx].productId);
                orgIdArr.push(hxArr[hxidx].orgId);
                orgCodeArr.push(hxArr[hxidx].orgCode);
                hxParams = {
                  code: codeArr.join(","),
                  orgId: orgIdArr.join(","),
                  orgCode: orgCodeArr.join(","),
                };
                wyIdArr.push(hxArr[hxidx].wyId);
              }
              let paramDmsStock = {
                productCodes: codeArr,
                supplierCode: '',
              }
              this.getChannelStockNew(paramDmsStock, wyIdArr, this.historyList);
              this.getPricesNew(hxParams, wyIdArr, this.historyList);
              this.getStocksNew(hxParams, wyIdArr, this.historyList);

            }

            if (qdArr && qdArr.length > 0) {
              //唯一Id数组
              let wyIdArr = [];
              let paramDmsPrice = {
                orgId: [],
                productId: [],
              };
              let paramDmsStock = {
                productCodes: [],
                supplierCode: "",
              };
              for (const qdidx in qdArr) {
                paramDmsPrice.orgId.push(qdArr[qdidx].orgId);
                paramDmsPrice.productId.push(qdArr[qdidx].productId);
                paramDmsStock.productCodes.push((qdArr[qdidx].productId).toString());
                wyIdArr.push(qdArr[qdidx].wyId);
              }
              paramDmsPrice = {
                orgId: paramDmsPrice.orgId.join(","),
                productId: paramDmsPrice.productId.join(","),
              };
              paramDmsStock = {
                productCodes: paramDmsStock.productCodes,
                supplierCode: qdArr[0].agentCisCode,
              };
              this.getChannelPriceNew(paramDmsPrice, wyIdArr, this.historyList);
              this.getChannelStockNew(paramDmsStock, wyIdArr, this.historyList);
            }
          }
          this.pageLoadFlag = false;
        }
      } catch (error) {
        console.log(error);
        this.pageLoadFlag = false;
      }
      // queryCustBrowseRecord({
      //   "num":12 //数量
      // }).then((res)=>{
      //   if(res.status == 200){
      //     this.historyList = res.data.data;
      //   }
      // }).catch((error)=>{
      //   console.log(error)
      // })
    },
    //排序调用
    sortFun(item) {
      if (item.name == "新品优先") {
        this.param.sortField = "scorePriorityNew";
      } else if (item.name == "专供优先") {
        this.param.sortField = "scorePrioritySpecial";
      } else if (item.name == "价格") {
        this.param.sortField = "price";
      } else if(item.name == "综合优先") {
		 this.param.sortField = 'scoreOverall';
	  }
      this.param.sortType =
        item.sortStatus == 0 ? "" : item.sortStatus == 1 ? "desc" : "asc";
      this.getProductSearch();
    },
  },
};
